import { baseNormalizeData } from './baseNormalizeData';

export const normalizeContentStatusData = (data: any) => {
	const { singleContentState } = baseNormalizeData(data);

	return {
		color: singleContentState.color,
		restrictionLevel: singleContentState.restrictionLevel,
		name: singleContentState.name,
	};
};
