import { baseNormalizeData } from './baseNormalizeData';

export const normalizeContentUpdatedData = (data: any) => {
	const { version } = baseNormalizeData(data);

	return {
		friendlyWhen: version.friendlyWhen,
		// Potentially, we can leverage the SaveIndicatorContext to get a lastModifiedDate for drafts.
		// To do so, we'd need to lift the SaveIndicatorContextProvider.
		// lastModifiedDate: metadata.lastModifiedDate,
	};
};
