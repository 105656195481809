import React from 'react';
import { Subscribe } from 'unstated';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import { Stack, xcss } from '@atlaskit/primitives';

import {
	ContentStateStateContainer,
	type EditorContentStateQueryType,
} from '@confluence/content-state';

import { CreatedRow } from './CreatedRow';
import { LengthRow } from './LengthRow';
import { CreatorRow } from './CreatorRow';
import { StatusRow } from './StatusRow';
import { ClassificationRow } from './ClassificationRow';

const i18n = defineMessages({
	propertiesSectionTitle: {
		id: 'details-panel.properties-section.title',
		defaultMessage: 'Properties',
		description: 'Title for the section containing properties related to content',
	},
});

const propertiesSectionStyles = xcss({
	paddingLeft: 'space.300',
	paddingRight: 'space.400',
	marginTop: 'space.300',
});

const itemsStyles = xcss({
	paddingLeft: 'space.075',
	paddingRight: 'space.075',
});

type ContentState = {
	state: EditorContentStateQueryType['singleContent'];
};

export const PropertiesSection = () => (
	<Stack testId="properties-section" xcss={propertiesSectionStyles}>
		<FormattedMessage {...i18n.propertiesSectionTitle} tagName="h4" />
		<Stack xcss={itemsStyles}>
			<CreatedRow />
			<CreatorRow />
			<LengthRow />
			<Subscribe to={[ContentStateStateContainer]}>
				{({ state }: ContentState) => {
					const { name = null, restrictionLevel, color = null } = state?.contentState ?? {};
					return (
						<StatusRow
							name={name}
							restrictionLevel={restrictionLevel}
							color={color}
							isContentStateAvailable={!!state?.contentState}
						/>
					);
				}}
			</Subscribe>
			<ClassificationRow />
		</Stack>
	</Stack>
);
