import gql from 'graphql-tag';

export const DetailsPanelRelatedContentQuery = gql`
	query DetailsPanelRelatedContentQuery(
		$contentId: ID!
		$experience: String!
		$contentType: String!
	) {
		getRecommendedPages(entityType: $contentType, entityId: $contentId, experience: $experience) {
			recommendedPages {
				content {
					id
					links {
						webui
					}
				}
			}
			status {
				isEnabled
			}
		}
	}
`;
