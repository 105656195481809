import gql from 'graphql-tag';

export const DetailsPanelContentStatusQuery = gql`
	query DetailsPanelContentStatusQuery($contentId: ID!) {
		content(id: $contentId, status: ["current", "draft"]) {
			nodes {
				id
				status
			}
		}
	}
`;
