import gql from 'graphql-tag';

export const DetailsPanelContentVersionQuery = gql`
	query DetailsPanelContentVersionQuery($contentId: ID!, $status: String!) {
		content(id: $contentId, status: [$status]) {
			nodes {
				id
				version {
					friendlyWhen
				}
			}
		}
	}
`;
