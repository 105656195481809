import React from 'react';

import { Stack, xcss } from '@atlaskit/primitives';

import { withErrorBoundary, Attribution } from '@confluence/error-boundary';
import { fg } from '@confluence/feature-gating';
import { ScreenEvent } from '@confluence/analytics';

import { ContributorSection } from './components/ContributorSection';
import { OwnerSection } from './components/OwnerSection';
import { PropertiesSection } from './components/PropertiesSection';
import { ActivitySection } from './components/ActivitySection';
import { RelatedContentSection } from './components/RelatedContentSection';
import { LabelsSection } from './components/LabelsSection';
import { PageModeSection } from './components/PageModeSection';

const detailsPanelBodyStyles = xcss({
	overflowY: 'auto',
	height: 'calc(100vh - 255px)', // 255px positions the details panel properly
});

const DetailsPanelComponent = () => {
	return (
		<>
			<Stack testId="details-panel-body" xcss={detailsPanelBodyStyles}>
				<OwnerSection />
				<ContributorSection />
				{fg('confluence_frontend_page_mode_in_details_panel') && <PageModeSection />}
				<ActivitySection />
				<PropertiesSection />
				<LabelsSection />
				<RelatedContentSection />
			</Stack>
			<ScreenEvent name="detailsPanelScreen" id="detailsPanelScreen" />
		</>
	);
};

export const DetailsPanelBody = withErrorBoundary({
	attribution: Attribution.COMMENTS,
})(DetailsPanelComponent);
