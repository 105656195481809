export const baseNormalizeData = (data: any = {}) => {
	const {
		content = {},
		contentAnalyticsViewers,
		contentContributors,
		getRecommendedPages,
		singleContent = {},
	} = data;
	const contentNode = content.nodes?.[0] ?? {};
	const {
		history = {},
		body = {},
		contentReactionsSummary,
		version,
		contentState,
		metadata,
		operations,
		type,
	} = contentNode;
	const { ownedBy, createdBy } = history;
	const { dynamic } = body;

	const { contentState: singleContentState, labels } = singleContent;

	return {
		ownedBy: ownedBy ?? {},
		createdBy: createdBy ?? {},
		metadata: metadata ?? {},
		labels: labels ?? {},
		contentReactionsSummary: contentReactionsSummary ?? {},
		contentAnalyticsViewers: contentAnalyticsViewers ?? {},
		contentContributors: contentContributors ?? {},
		getRecommendedPages: getRecommendedPages ?? {},
		version: version ?? {},
		contentState: contentState ?? {},
		singleContentState: singleContentState ?? {},
		dynamic: dynamic ?? {},
		contentOperations: operations ?? [],
		contentType: type ?? '',
	};
};
