import { baseNormalizeData } from './baseNormalizeData';

export const normalizeRelatedContentData = (data: any) => {
	const { getRecommendedPages } = baseNormalizeData(data);

	return {
		recommendedPages: getRecommendedPages.recommendedPages ?? [],
		isRecommendedPagesEnabled: getRecommendedPages?.status?.isEnabled ?? false,
	};
};
