import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { Inline, Text } from '@atlaskit/primitives';
import ListBulletedIcon from '@atlaskit/icon/core/list-bulleted';
import Skeleton from '@atlaskit/skeleton';

import { ReadTime } from '@confluence/read-time';
import { usePageContentId } from '@confluence/page-context';

import { useContentBody } from '../hooks/useContentBody';

import { DetailsPanelItem } from './DetailsPanelItem';
import { InlineRetryError } from './InlineRetryError';

const i18n = defineMessages({
	length: {
		id: 'details-panel.properties-section.length-row',
		defaultMessage: 'Length',
		description: "A title that accompanies the content's read time in minutes",
	},
	wordCount: {
		id: 'details-panel.properties-section.length-row.word-count',
		defaultMessage: '{wordCount} words',
		description:
			'This message informs the user about the total number of words used in their content.',
	},
});

export const LengthRow = () => {
	const { formatMessage } = useIntl();
	const [contentId] = usePageContentId();

	const { data, error, refetch, loading } = useContentBody();
	const { wordCount } = data;

	const renderLengthItem = () => {
		if (loading) {
			return <Skeleton width="80px" height="24px" borderRadius={3} testId="length-row-loading" />;
		}

		if (error) {
			return <InlineRetryError error={error} refetch={refetch} />;
		}

		return (
			<Inline>
				<Text>
					{formatMessage(i18n.wordCount, {
						wordCount,
					})}
				</Text>
				<ReadTime contentId={contentId ?? ''} />
			</Inline>
		);
	};

	return (
		<DetailsPanelItem itemName={formatMessage(i18n.length)} icon={<ListBulletedIcon label="" />}>
			{renderLengthItem()}
		</DetailsPanelItem>
	);
};
