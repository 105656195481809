import { useMemo } from 'react';
import { useQuery } from 'react-apollo';

import { usePageContentId } from '@confluence/page-context';

import { DetailsPanelContentCreatedByQuery } from '../queries/DetailsPanelContentCreatedByQuery.graphql';
import type {
	DetailsPanelContentCreatedByQuery as ContentCreatedByQuery,
	DetailsPanelContentCreatedByQueryVariables as ContentCreatedByQueryVariables,
} from '../queries/__types__/DetailsPanelContentCreatedByQuery';
import { normalizeContentCreatorData } from '../normalizers/normalizeContentCreatorData';

import { useContentStatus } from './useContentStatus';
import { useContentOwner } from './useContentOwner';
import { ContentStatus } from './contentStatus';

export const useContentCreator = () => {
	const [contentId] = usePageContentId();

	const {
		contentStatus,
		loading: isContentStatusLoading,
		error: contentStatusError,
		refetch: refetchContentStatus,
	} = useContentStatus();

	const isContentStatusDraft = contentStatus === ContentStatus.DRAFT;

	const {
		data: createdByData,
		loading: isCreatedByLoading,
		error: createdByError,
		refetch: refetchCreatedBy,
	} = useQuery<ContentCreatedByQuery, ContentCreatedByQueryVariables>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		DetailsPanelContentCreatedByQuery,
		{
			variables: {
				contentId: contentId ?? '',
				status: contentStatus ?? '',
			},
			skip:
				isContentStatusLoading ||
				!!contentStatusError ||
				!contentStatus ||
				!contentId ||
				isContentStatusDraft,
		},
	);

	const normalizedCreatedByData = useMemo(
		() => normalizeContentCreatorData(createdByData),
		[createdByData],
	);

	const {
		data: contentOwnerData,
		loading: isContentOwnerLoading,
		error: contentOwnerError,
		refetch: refetchContentOwner,
	} = useContentOwner({
		skip: !isContentStatusDraft,
	});

	const refetchUserData = isContentStatusDraft ? refetchContentOwner : refetchCreatedBy;

	return {
		data: isContentStatusDraft ? contentOwnerData : normalizedCreatedByData,
		loading: isContentStatusLoading || isCreatedByLoading || isContentOwnerLoading,
		error: contentStatusError || createdByError || contentOwnerError,
		refetch: contentStatusError ? refetchContentStatus : refetchUserData,
	};
};
