import { useMemo } from 'react';
import { useQuery } from 'react-apollo';

import { usePageContentId } from '@confluence/page-context';

import { DetailsPanelContentBodyQuery } from '../queries/DetailsPanelContentBodyQuery.graphql';
import type {
	DetailsPanelContentBodyQueryVariables as ContentBodyQueryVariables,
	DetailsPanelContentBodyQuery as ContentBodyQuery,
} from '../queries/__types__/DetailsPanelContentBodyQuery';

import { getContentFeatures } from './getContentFeatures';
import { useContentStatus } from './useContentStatus';

export const useContentBody = () => {
	const [contentId] = usePageContentId();

	const {
		contentStatus,
		loading: isContentStatusLoading,
		error: contentStatusError,
		refetch: refetchContentStatus,
	} = useContentStatus();

	const {
		data: contentBodyData,
		loading: isContentBodyLoading,
		error: contentBodyError,
		refetch: refetchContentBody,
	} = useQuery<ContentBodyQuery, ContentBodyQueryVariables>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations
		DetailsPanelContentBodyQuery,
		{
			variables: {
				contentId: contentId ?? '',
				status: contentStatus ?? '',
			},
			skip: isContentStatusLoading || !!contentStatusError || !contentStatus || !contentId,
		},
	);

	const contentFeatures = useMemo(() => getContentFeatures(contentBodyData), [contentBodyData]);

	return {
		data: contentFeatures,
		loading: isContentStatusLoading || isContentBodyLoading,
		error: contentStatusError || contentBodyError,
		refetch: contentStatusError ? refetchContentStatus : refetchContentBody,
	};
};
