import { useMemo } from 'react';
import { useQuery } from 'react-apollo';

import { usePageContentId } from '@confluence/page-context';

import { DetailsPanelContentVersionQuery } from '../queries/DetailsPanelContentVersionQuery.graphql';
import type {
	DetailsPanelContentVersionQueryVariables as ContentVersionQueryVariables,
	DetailsPanelContentVersionQuery as ContentVersionQuery,
} from '../queries/__types__/DetailsPanelContentVersionQuery';
import { normalizeContentUpdatedData } from '../normalizers/normalizeContentUpdatedData';

import { useContentStatus } from './useContentStatus';

export const useContentUpdated = () => {
	const [contentId] = usePageContentId();

	const {
		contentStatus,
		loading: isContentStatusLoading,
		error: contentStatusError,
		refetch: refetchContentStatus,
	} = useContentStatus();

	const { data, loading, error, refetch } = useQuery<
		ContentVersionQuery,
		ContentVersionQueryVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		DetailsPanelContentVersionQuery,
		{
			variables: {
				contentId: contentId ?? '',
				status: contentStatus ?? '',
			},
			skip: isContentStatusLoading || !!contentStatusError || !contentStatus || !contentId,
		},
	);

	const normalizedData = useMemo(() => normalizeContentUpdatedData(data), [data]);

	return {
		data: normalizedData,
		loading,
		error: contentStatusError || error,
		refetch: !!contentStatusError ? refetchContentStatus : refetch,
	};
};
