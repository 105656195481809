import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import { SmartCardProvider } from '@atlaskit/link-provider';
import { Card } from '@atlaskit/smart-card';
import { Stack, xcss, Box } from '@atlaskit/primitives';

import { useRouteActions } from '@confluence/route-manager';
import { CONTEXT_PATH } from '@confluence/named-routes';

import { useRelatedContent } from '../hooks/useRelatedContent';
import type { DetailsPanelRelatedContentQuery_getRecommendedPages_recommendedPages as RecommendedPages } from '../queries/__types__/DetailsPanelRelatedContentQuery';

import { InlineRetryError } from './InlineRetryError';
import { getOrigin } from './linkUtils';

const i18n = defineMessages({
	relatedContentSectionTitle: {
		id: 'details-panel.related-content-section.title',
		defaultMessage: 'Related content',
		description: 'Title for the section containing links to related content',
	},
});

const relatedContentSectionStyles = xcss({
	paddingLeft: 'space.300',
	paddingRight: 'space.400',
	marginTop: 'space.300',
});

const relatedContentItemContainerStyles = xcss({
	marginTop: 'space.100',
});

const relatedContentItemStyles = xcss({
	marginTop: 'space.050',
});

const MAX_RECOMMENDED_PAGES = 6;

export const RelatedContentSection = () => {
	const { push } = useRouteActions();
	const origin = getOrigin(window);
	const { data, error, refetch } = useRelatedContent();
	const { recommendedPages, isRecommendedPagesEnabled } = data;

	if (!error && (recommendedPages.length === 0 || !isRecommendedPagesEnabled)) return null;

	return (
		<Stack testId="related-content-section" xcss={relatedContentSectionStyles}>
			<FormattedMessage {...i18n.relatedContentSectionTitle} tagName="h4" />
			{error ? (
				<InlineRetryError error={error} refetch={refetch} />
			) : (
				<SmartCardProvider product="CONFLUENCE">
					<Stack xcss={relatedContentItemContainerStyles}>
						{recommendedPages
							.slice(0, MAX_RECOMMENDED_PAGES)
							.map(({ content }: RecommendedPages) => {
								const { id, links } = content;
								const path = `${CONTEXT_PATH}${links?.webui}`;

								return (
									<Box key={id} xcss={relatedContentItemStyles}>
										<Card
											onClick={() => push(path)}
											url={`${origin}${path}`}
											appearance="inline"
											truncateInline
										/>
									</Box>
								);
							})}
					</Stack>
				</SmartCardProvider>
			)}
		</Stack>
	);
};
