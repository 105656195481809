import { useMemo } from 'react';
import { useQuery } from 'react-apollo';

import { usePageContentId } from '@confluence/page-context';
import { useGetPageMode } from '@confluence/page-utils/entry-points/useGetPageMode';
import { PageMode } from '@confluence/page-utils/entry-points/enums';
import type { ContentState } from '@confluence/content-state';

import { DetailsPanelSingleContentStateQuery } from '../queries/DetailsPanelSingleContentStateQuery.graphql';
import type {
	DetailsPanelSingleContentStateQueryVariables as SingleContentStateQueryVariables,
	DetailsPanelSingleContentStateQuery as SingleContentStateQuery,
} from '../queries/__types__/DetailsPanelSingleContentStateQuery';
import { normalizeContentStatusData } from '../normalizers/normalizeContentStatusData';

import { useContentStatus } from './useContentStatus';
import { ContentStatus } from './contentStatus';

export type AvailableContentState = ContentState & {
	isContentStateAvailable: boolean;
};

export const useContentState = ({
	isContentStateAvailable,
	...contentState
}: AvailableContentState) => {
	const [contentId] = usePageContentId();
	const pageMode = useGetPageMode();

	const {
		contentStatus,
		loading: isContentStatusLoading,
		error: contentStatusError,
		refetch: refetchContentStatus,
	} = useContentStatus({ skip: isContentStateAvailable });

	const { data, loading, error, refetch } = useQuery<
		SingleContentStateQuery,
		SingleContentStateQueryVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations
		DetailsPanelSingleContentStateQuery,
		{
			variables: {
				contentId: contentId ?? '',
				status: contentStatus ?? '',
				isDraft: contentStatus === ContentStatus.DRAFT || pageMode === PageMode.EDIT,
			},
			skip:
				isContentStateAvailable ||
				isContentStatusLoading ||
				!!contentStatusError ||
				!contentStatus ||
				!contentId,
		},
	);

	const normalizedData = useMemo(() => normalizeContentStatusData(data), [data]);

	return {
		data: isContentStateAvailable ? contentState : normalizedData,
		loading,
		error: contentStatusError || error,
		refetch: !!contentStatusError ? refetchContentStatus : refetch,
	};
};
