import { useMemo } from 'react';
import { useQuery } from 'react-apollo';

import { usePageContentId } from '@confluence/page-context';

import { DetailsPanelContentOwnerQuery } from '../queries/DetailsPanelContentOwnerQuery.graphql';
import type {
	DetailsPanelContentOwnerQueryVariables as ContentOwnerQueryVariables,
	DetailsPanelContentOwnerQuery as ContentOwnerQuery,
} from '../queries/__types__/DetailsPanelContentOwnerQuery';
import { normalizeContentOwnerData } from '../normalizers/normalizeContentOwnerData';

import { useContentStatus } from './useContentStatus';
import { ContentStatus } from './contentStatus';

type UseContentOwnerProps = {
	skip?: boolean;
};

export const useContentOwner = ({ skip = false }: UseContentOwnerProps = {}) => {
	const [contentId] = usePageContentId();

	const {
		contentStatus,
		loading: isContentStatusLoading,
		error: contentStatusError,
		refetch: refetchContentStatus,
	} = useContentStatus({ skip });

	const { data, loading, error, refetch } = useQuery<ContentOwnerQuery, ContentOwnerQueryVariables>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		DetailsPanelContentOwnerQuery,
		{
			variables: {
				contentId: contentId ?? '',
				status: contentStatus ?? '',
			},
			skip: skip || isContentStatusLoading || !!contentStatusError || !contentStatus || !contentId,
		},
	);

	const normalizedData = useMemo(() => normalizeContentOwnerData(data), [data]);

	return {
		data: normalizedData,
		loading,
		error: contentStatusError || error,
		refetch: !!contentStatusError ? refetchContentStatus : refetch,
		isChangePageOwnerButtonVisible: contentStatus !== ContentStatus.DRAFT,
	};
};
