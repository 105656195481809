import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { Text } from '@atlaskit/primitives';

const i18n = defineMessages({
	none: {
		id: 'details-panel.none-label',
		defaultMessage: 'None',
		description:
			'Label used to indicate that no data is available for a specific field in the Details Panel.',
	},
});

export const NoneLabel = () => {
	const { formatMessage } = useIntl();

	return <Text color="color.text.disabled">{formatMessage(i18n.none)}</Text>;
};
