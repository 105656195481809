import { useMemo } from 'react';
import { useQuery } from 'react-apollo';

import { usePageContentId } from '@confluence/page-context';

import { DetailsPanelContentViewsQuery } from '../queries/DetailsPanelContentViewsQuery.graphql';
import type {
	DetailsPanelContentViewsQueryVariables as ContentViewsQueryVariables,
	DetailsPanelContentViewsQuery as ContentViewsRowQuery,
} from '../queries/__types__/DetailsPanelContentViewsQuery';
import { normalizeContentViewsData } from '../normalizers/normalizeContentViewsData';

import { ContentStatus } from './contentStatus';
import { useContentStatus } from './useContentStatus';

export const useContentViews = () => {
	const [contentId] = usePageContentId();

	const {
		contentStatus,
		loading: isContentStatusLoading,
		error: contentStatusError,
		refetch: refetchContentStatus,
	} = useContentStatus();

	const { data, loading, error, refetch } = useQuery<
		ContentViewsRowQuery,
		ContentViewsQueryVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		DetailsPanelContentViewsQuery,
		{
			variables: {
				contentId: contentId ?? '',
				isDraft: contentStatus === ContentStatus.DRAFT,
			},
			skip: isContentStatusLoading || !!contentStatusError || !contentStatus || !contentId,
		},
	);

	const normalizedData = useMemo(() => normalizeContentViewsData(data), [data]);

	return {
		data: normalizedData,
		loading,
		error: contentStatusError || error,
		refetch: !!contentStatusError ? refetchContentStatus : refetch,
	};
};
