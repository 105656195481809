import {
	B200,
	B50,
	G200,
	G50,
	Y200,
	Y50,
	R200,
	R50,
	P200,
	P50,
	B500,
	G500,
	Y500,
	R500,
	P500,
} from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

export const PRIMARY_COLORS = {
	BLUE: token('color.background.accent.blue.subtle', B200),
	GREEN: token('color.background.accent.green.subtle', G200),
	YELLOW: token('color.background.accent.yellow.subtle', Y200),
	RED: token('color.background.accent.red.subtle', R200),
	PURPLE: token('color.background.accent.purple.subtle', P200),
	VERIFIED: token('color.icon.accent.blue', B500),
};

export const SECONDARY_COLORS = {
	BLUE: token('color.background.accent.blue.subtlest', B50),
	GREEN: token('color.background.accent.green.subtlest', G50),
	YELLOW: token('color.background.accent.yellow.subtlest', Y50),
	RED: token('color.background.accent.red.subtlest', R50),
	PURPLE: token('color.background.accent.purple.subtlest', P50),
};

export const HOVER_COLORS = {
	BLUE: token('color.background.accent.blue.subtlest.hovered', B500),
	GREEN: token('color.background.accent.green.subtlest.hovered', G500),
	YELLOW: token('color.background.accent.yellow.subtlest.hovered', Y500),
	RED: token('color.background.accent.red.subtlest.hovered', R500),
	PURPLE: token('color.background.accent.purple.subtlest.hovered', P500),
};

export const COLOR_NAMES = {
	BLUE: 'blue',
	GREEN: 'green',
	YELLOW: 'yellow',
	RED: 'red',
	PURPLE: 'purple',
};
