import { reduce } from '@atlaskit/adf-utils/traverse';
import type { ADDoc } from '@atlaskit/editor-common/validator';

import type { DetailsPanelContentBodyQuery as ContentBodyQuery } from '../queries/__types__/DetailsPanelContentBodyQuery';

const wordCount = (text?: string) => {
	if (text) {
		const matches = text.match(/\S+/g);
		if (matches) {
			return matches.length;
		}
	}

	return 0;
};

export const extractContentFeatures = (adf: ADDoc) => {
	const initialFeatures = {
		wordCount: 0,
	};

	if (adf) {
		return reduce(
			adf,
			(currentValue, node) => {
				if (node.type === 'text') {
					return {
						...currentValue,
						wordCount: currentValue.wordCount + wordCount(node.text),
					};
				}
				return currentValue;
			},
			initialFeatures,
		);
	}

	return initialFeatures;
};

const getAdf = (data: ContentBodyQuery | undefined) => {
	const representation = data?.content?.nodes?.[0]?.body?.dynamic?.representation;
	if (representation === 'atlas_doc_format') {
		const adfString = data?.content?.nodes?.[0]?.body?.dynamic?.value;

		if (adfString) {
			try {
				return JSON.parse(adfString);
			} catch {
				return null;
			}
		}
	}

	return null;
};

export const getContentFeatures = (data?: ContentBodyQuery) => {
	const adf = getAdf(data);

	return extractContentFeatures(adf);
};
