import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import ClockIcon from '@atlaskit/icon/core/clock';
import Skeleton from '@atlaskit/skeleton';

import { CONTENT_HISTORY } from '@confluence/named-routes';
import { usePageContentId, usePageSpaceKey } from '@confluence/page-context';

import { useContentUpdated } from '../hooks/useContentUpdated';

import { DetailsPanelItem, DetailsPanelItemLinkButton } from './DetailsPanelItem';
import { InlineRetryError } from './InlineRetryError';

const i18n = defineMessages({
	updatedSection: {
		id: 'details-panel.activity-section.updated-row',
		defaultMessage: 'Updated',
		description: 'Text that appears next to a timestamp notifying when content was last updated',
	},
});

export const UpdatedRow = () => {
	const { formatMessage } = useIntl();
	const [contentId] = usePageContentId();
	const [spaceKey] = usePageSpaceKey();
	const { data, error, refetch, loading } = useContentUpdated();
	const { friendlyWhen } = data;

	const isLinkButtonDisabled = !contentId || !spaceKey;
	const href = !isLinkButtonDisabled ? CONTENT_HISTORY.toUrl({ contentId, spaceKey }) : undefined;

	const renderUpdatedItem = () => {
		if (loading) {
			return <Skeleton width="48px" height="24px" borderRadius={3} testId="updated-row-loading" />;
		}

		if (error) {
			return <InlineRetryError error={error} refetch={refetch} />;
		}

		return (
			<DetailsPanelItemLinkButton isDisabled={isLinkButtonDisabled} href={href}>
				{friendlyWhen}
			</DetailsPanelItemLinkButton>
		);
	};

	return (
		<DetailsPanelItem itemName={formatMessage(i18n.updatedSection)} icon={<ClockIcon label="" />}>
			{renderUpdatedItem()}
		</DetailsPanelItem>
	);
};
