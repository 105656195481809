import React, { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import CommentIcon from '@atlaskit/icon/core/comment';
import ChevronRightIcon from '@atlaskit/icon/utility/chevron-right';
import { token } from '@atlaskit/tokens';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useCommentsData } from '@confluence/comments-data';
import { useShowCommentsPanel } from '@confluence/comments-panel';

import { DetailsPanelItem, DetailsPanelItemLinkButton } from './DetailsPanelItem';
import { NoneLabel } from './NoneLabel';

const i18n = defineMessages({
	commentsSection: {
		id: 'details-panel.activity-section.comments-row',
		defaultMessage: 'Comments',
		description:
			'Text that appears next to a number indicating the number of comments on specific content',
	},
});

export const CommentsRow = () => {
	const { formatMessage } = useIntl();
	const { showCommentsPanel } = useShowCommentsPanel();
	const [{ orderedActiveAnnotationIdList }] = useCommentsData();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const commentCount = orderedActiveAnnotationIdList.length;

	const handleCommentsItemClick = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'link',
				actionSubjectId: 'commentsDetailsPanel',
				source: 'contentWrapper',
			},
		}).fire();
		showCommentsPanel();
	}, [createAnalyticsEvent, showCommentsPanel]);

	return (
		<DetailsPanelItem
			itemName={formatMessage(i18n.commentsSection)}
			icon={<CommentIcon label="" />}
		>
			{commentCount > 0 ? (
				<DetailsPanelItemLinkButton
					onClick={handleCommentsItemClick}
					iconAfter={() => <ChevronRightIcon label="" color={token('color.link')} />}
				>
					{commentCount}
				</DetailsPanelItemLinkButton>
			) : (
				<NoneLabel />
			)}
		</DetailsPanelItem>
	);
};
