import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';

import { Inline, Stack, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import EpicIcon from '@atlaskit/icon/core/epic';
import BookWithBookmarkIcon from '@atlaskit/icon/core/book-with-bookmark';
import InformationIcon from '@atlaskit/icon/utility/information';

import { useIsLivePage } from '@confluence/live-pages-utils/entry-points/useIsLivePage';

import { DetailsPanelItem, DetailsPanelItemLinkButton } from './DetailsPanelItem';

const i18n = defineMessages({
	pageModeSectionTitle: {
		id: 'details-panel.page-mode-section.title',
		defaultMessage: 'Page mode',
		description: "Title for section containing the page's mode (whether it is a live page or not)",
	},
	livePageDescription: {
		id: 'details-panel.page-mode-section.live-page-description',
		defaultMessage: 'Live page',
		description: 'Text that describes the mode of a page that is live',
	},
	publishedPageDescription: {
		id: 'details-panel.page-mode-section.published-page-description',
		defaultMessage: 'Published',
		description:
			'Text that describes the mode of a page that is published, not live.  (It has separate published and draft versions.)',
	},
	publishButtonText: {
		id: 'details-panel.page-mode-section.publish-button.text',
		defaultMessage: 'Publish',
		description:
			'Text for a button that will save the current contents of this live page and convert it to a `published` format, with separate versions for viewing and editing.',
	},
	switchToLiveButtonText: {
		id: 'details-panel.page-mode-section.switch-button.text',
		defaultMessage: 'Switch to live edit',
		description:
			'Text for a button that will convert the current content of a published page into a live page, where there is one version for both viewing and editing.',
	},
});

const pageModeSectionStyles = xcss({
	paddingLeft: 'space.300',
	paddingRight: 'space.300',
	marginTop: 'space.300',
});

const itemsStyles = xcss({
	paddingLeft: 'space.075',
	paddingRight: 'space.075',
});

const liveSettings = {
	descriptionI18n: i18n.livePageDescription,
	// TODO Rick has said we will need to switch this to a different icon, since the "lightning bolt" EpicIcon is reserved for Jira Epics.
	icon: EpicIcon,
	buttonI18n: i18n.publishButtonText,
};
const publishedSettings = {
	descriptionI18n: i18n.publishedPageDescription,
	icon: BookWithBookmarkIcon,
	buttonI18n: i18n.switchToLiveButtonText,
};

export const PageModeSection: React.FC = () => {
	const { formatMessage } = useIntl();
	const isLivePage = useIsLivePage();
	const settings = isLivePage ? liveSettings : publishedSettings;
	const Icon = settings.icon;

	return (
		<Stack testId="page-mode-section" xcss={pageModeSectionStyles}>
			<FormattedMessage {...i18n.pageModeSectionTitle} tagName="h4" />
			<Stack xcss={itemsStyles}>
				<DetailsPanelItem
					itemName={formatMessage(settings.descriptionI18n)}
					icon={<Icon label="" />}
				>
					<Inline alignBlock="center">
						<InformationIcon label="" color={token('color.link')} />
						<DetailsPanelItemLinkButton href="#">
							<FormattedMessage {...settings.buttonI18n} />
						</DetailsPanelItemLinkButton>
					</Inline>
				</DetailsPanelItem>
			</Stack>
		</Stack>
	);
};
