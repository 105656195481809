import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';

import { Stack, Inline, Flex, xcss } from '@atlaskit/primitives';
import { IconButton } from '@atlaskit/button/new';
import AddIcon from '@atlaskit/icon/core/add';
import Tag, { SimpleTag } from '@atlaskit/tag';

import { usePageContentId, usePageSpaceKey } from '@confluence/page-context';
import { useDialogs } from '@confluence/dialogs/entry-points/useDialogs';
import { EditLabelDialogLoader } from '@confluence/labels';

import { useContentLabels } from '../hooks/useContentLabels';
import type { DetailsPanelContentLabelsQuery_singleContent_labels_nodes as LabelNode } from '../queries/__types__/DetailsPanelContentLabelsQuery';

import { InlineRetryError } from './InlineRetryError';

const i18n = defineMessages({
	labelsSectionTitle: {
		id: 'details-panel.labels-section.title',
		defaultMessage: 'Labels',
		description: 'Title for section containing labels related to content',
	},
	addButtonLabel: {
		id: 'details-panel.labels-section.add-button-label',
		defaultMessage: 'Add label',
		description: 'aria-label for button to add label for content',
	},
});

const labelsSectionStyles = xcss({
	paddingLeft: 'space.300',
	paddingRight: 'space.300',
	marginTop: 'space.150',
});

export const LabelsSection: React.FC = () => {
	const { formatMessage } = useIntl();
	const [contentId = ''] = usePageContentId();
	const [spaceKey = ''] = usePageSpaceKey();
	const { showModal } = useDialogs();
	const { data, error, refetch, handleDeleteLabelClick, loading } = useContentLabels();
	const { labels, hasEditPermissions } = data;

	return (
		<Stack testId="labels-section" xcss={labelsSectionStyles}>
			<Inline alignBlock="center" spread="space-between">
				<FormattedMessage {...i18n.labelsSectionTitle} tagName="h4" />
				{error ? (
					<InlineRetryError error={error} refetch={refetch} />
				) : (
					hasEditPermissions && (
						<IconButton
							appearance="subtle"
							icon={AddIcon}
							label={formatMessage(i18n.addButtonLabel)}
							onClick={() =>
								showModal(EditLabelDialogLoader, {
									contentId,
									spaceKey,
									source: 'detailsPanel',
								})
							}
							isDisabled={!contentId || !spaceKey || loading}
							testId="add-label-button"
						/>
					)
				)}
			</Inline>
			{labels.length > 0 && (
				<Flex direction="row" alignItems="center" wrap="wrap">
					{labels.map((labelNode: LabelNode) => {
						if (labelNode?.label) {
							const label = labelNode.label;

							if (hasEditPermissions) {
								return (
									<Tag
										key={label}
										text={label}
										onAfterRemoveAction={async () => {
											await handleDeleteLabelClick({
												variables: {
													contentId,
													label,
												},
											});
											await refetch();
										}}
									/>
								);
							} else {
								return <SimpleTag testId="readonly-label" key={label} text={label} />;
							}
						}
					})}
				</Flex>
			)}
		</Stack>
	);
};
