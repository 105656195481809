import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import AvatarGroup from '@atlaskit/avatar-group';
import { Box, Inline, xcss } from '@atlaskit/primitives';
import Skeleton from '@atlaskit/skeleton';

import { useContentContributors } from '../hooks/useContentContributors';

import { InlineRetryError } from './InlineRetryError';

const contributorsContainerStyles = xcss({
	paddingLeft: 'space.300',
	paddingRight: 'space.300',
	paddingTop: 'space.200',
});

const i18n = defineMessages({
	contributorCount: {
		id: 'details-panel.contributor-section.contributor-count',
		defaultMessage:
			'{contributorsCount} {contributorsCount, plural, one {contributor} other {contributors}}',
		description: 'The number of users who have contributed to a given page',
	},
});

export const ContributorSection = () => {
	const { data, error, refetch, loading } = useContentContributors();
	const { count, nodes } = data;
	if (nodes.length === 0 && !error) return null;

	const renderContributorsSectionContent = () => {
		if (loading) {
			return (
				<Inline alignBlock="center" space="space.100" testId="contributors-section-loading">
					<Skeleton width="68px" height="24px" borderRadius={3} />
					<Skeleton width="114px" height="24px" borderRadius={3} />
				</Inline>
			);
		}

		if (error) {
			return <InlineRetryError error={error} refetch={refetch} />;
		}

		return (
			<Inline alignBlock="center" spread="space-between">
				<FormattedMessage {...i18n.contributorCount} values={{ contributorsCount: count }} />
				<AvatarGroup maxCount={3} appearance="stack" data={nodes ?? []} />
			</Inline>
		);
	};

	return (
		<Box xcss={contributorsContainerStyles} testId="contributors-section">
			{renderContributorsSectionContent()}
		</Box>
	);
};
