import { baseNormalizeData } from './baseNormalizeData';

export const normalizeContentLabelsData = (data: any) => {
	const { labels, contentOperations, contentType } = baseNormalizeData(data);

	const hasEditPermissions = contentOperations?.find(
		(item: { operation: string; targetType: string }) => {
			return item?.operation === 'update' && item?.targetType === contentType;
		},
	);

	return {
		labels: labels.nodes ?? [],
		hasEditPermissions,
	};
};
