import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import EyeOpenIcon from '@atlaskit/icon/core/eye-open';
import Skeleton from '@atlaskit/skeleton';

import { useSessionData, ConfluenceEdition } from '@confluence/session-data';
import { usePageContentId, useContentType, usePageSpaceKey } from '@confluence/page-context';
import { getContentAnalyticsLink } from '@confluence/confluence-analytics/entry-points/getContentAnalyticsLink';

import { useContentViews } from '../hooks/useContentViews';

import { DetailsPanelItem, DetailsPanelItemLinkButton } from './DetailsPanelItem';
import { InlineRetryError } from './InlineRetryError';

const i18n = defineMessages({
	viewsSection: {
		id: 'details-panel.activity-section.views-row',
		defaultMessage: 'Views',
		description:
			'Text that appears next to a number indicating the total views on specific content',
	},
});

const ViewsRow = () => {
	const { formatMessage } = useIntl();
	const [contentType] = useContentType();
	const [spaceKey] = usePageSpaceKey();
	const [contentId] = usePageContentId();
	const { data, error, refetch, loading } = useContentViews();
	const { count } = data;
	const isVisible = error || count || loading;

	const renderViewsItem = () => {
		if (loading) {
			return <Skeleton width="48px" height="24px" borderRadius={3} testId="views-row-loading" />;
		}

		if (error) {
			return <InlineRetryError error={error} refetch={refetch} />;
		}

		return (
			<DetailsPanelItemLinkButton
				href={getContentAnalyticsLink({ contentType, spaceKey, contentId }) ?? '#'}
			>
				{count}
			</DetailsPanelItemLinkButton>
		);
	};

	return isVisible ? (
		<DetailsPanelItem itemName={formatMessage(i18n.viewsSection)} icon={<EyeOpenIcon label="" />}>
			{renderViewsItem()}
		</DetailsPanelItem>
	) : null;
};

export const ViewsRowWrapper = () => {
	const { edition } = useSessionData();
	const isNotFreeEdition = edition !== ConfluenceEdition.FREE;

	return isNotFreeEdition ? <ViewsRow /> : null;
};
