import type { UpdateQueryOptions } from 'apollo-client';

import type {
	DetailsPanelContentReactionsQueryVariables as ContentReactionsQueryVariables,
	DetailsPanelContentReactionsQuery as ContentReactionsQuery,
} from '../queries/__types__/DetailsPanelContentReactionsQuery';

interface ReactionSummary {
	emojiId: string;
	count: number;
	reacted: boolean;
	id: string;
	__typename: string;
}

interface ContentReactionsSummary {
	reactionsContainerAri: string;
	reactionsAri: string;
	reactionsSummaryForEmoji: ReactionSummary[];
	reactionsCount: number;
	ari: string;
	containerAri: string;
}

interface NodeWithContentReactionsSummary {
	id: string | null;
	contentReactionsSummary: ContentReactionsSummary;
	__typename: string;
}

export const updateContentReactionsCache =
	(
		updateQuery: (
			mapFn: (
				previousQueryResult: ContentReactionsQuery,
				options: UpdateQueryOptions<ContentReactionsQueryVariables>,
			) => ContentReactionsQuery,
		) => void,
	) =>
	(emojiId: string, actionType: 'add' | 'delete') =>
		updateQuery((prev) => {
			const nodes = prev.content?.nodes ?? [];

			const updatedNodes = nodes
				.filter(
					(node): node is NodeWithContentReactionsSummary =>
						node !== null && node.contentReactionsSummary !== null,
				)
				.map((node) => {
					const { reactionsSummaryForEmoji, reactionsContainerAri, reactionsAri } =
						node.contentReactionsSummary;
					const updatedReactionsSummary = reactionsSummaryForEmoji
						.map((reaction) => {
							if (reaction.emojiId === emojiId) {
								const newCount = reaction.count + (actionType === 'add' ? 1 : -1);
								return {
									...reaction,
									count: newCount,
									reacted: newCount > 0,
								};
							}
							return reaction;
						})
						.filter((reaction) => reaction.count > 0);

					if (
						actionType === 'add' &&
						!updatedReactionsSummary.some((reaction) => reaction.emojiId === emojiId)
					) {
						updatedReactionsSummary.push({
							emojiId,
							count: 1,
							reacted: true,
							id: `${reactionsContainerAri}|${reactionsAri}|${emojiId}`,
							__typename: 'ReactionsSummaryForEmoji',
						});
					}

					const totalReactionsCount = updatedReactionsSummary.reduce(
						(sum, reaction) => sum + reaction.count,
						0,
					);

					return {
						...node,
						id: node.id,
						contentReactionsSummary: {
							...node.contentReactionsSummary,
							reactionsCount: totalReactionsCount,
							reactionsSummaryForEmoji: updatedReactionsSummary,
							ari: node.contentReactionsSummary.ari,
							containerAri: node.contentReactionsSummary.containerAri,
						},
					};
				});
			return {
				content: {
					nodes: updatedNodes,
					__typename: 'PaginatedContentListWithChild',
				},
			};
		});
