import React, { useCallback } from 'react';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { css } from '@compiled/react';
import { defineMessages, useIntl } from 'react-intl-next';
import { useGetPageMode } from '@confluence/page-utils/entry-points/useGetPageMode';
import { PageMode } from '@confluence/page-utils/entry-points/enums';

import { Flex, xcss, Box } from '@atlaskit/primitives';
import AlignLeftIcon from '@atlaskit/icon/core/align-left';
import CommentIcon from '@atlaskit/icon/core/comment';
import { token } from '@atlaskit/tokens';
import { IconButton } from '@atlaskit/button/new';
import { layers } from '@atlaskit/theme/constants';
import { AnnotationUpdateEvent } from '@atlaskit/editor-common/types';

import { AIFloatingContextMenu } from '@confluence/ai-floating-context-menu';
import { useIsLivePage } from '@confluence/live-pages-utils/entry-points/useIsLivePage';
import { fg } from '@confluence/feature-gating';
import { useUnreadInlineComments } from '@confluence/unread-comments';
import { PageHeaderLoomEntryPoint } from '@confluence/page-header-loom-button';
import { useAnnotations, useAnnotationsDispatch } from '@confluence/annotation-provider-store';
import { getRendererAnnotationEventEmitter } from '@confluence/annotation-event-emitter';
import {
	useCommentContentContext,
	useCommentContentDispatchContext,
} from '@confluence/comment-context';
import { useDialogs } from '@confluence/dialogs/entry-points/useDialogs';
import { CommentWarningDialog } from '@confluence/comment-dialogs';
import { useQuickstartEligible, useQuickstartState } from '@confluence/onboarding-quickstart';
import { useShowCommentsPanel } from '@confluence/comments-panel';
import { useShowDetailsPanel } from '@confluence/details-panel';
import { useObjectSidebar } from '@confluence/object-sidebar-api';

const objectSidebarStyles = css({
	position: 'fixed',
	right: 0,
	bottom: 0,
	paddingRight: `${token('space.300')}`,
	paddingBottom: `${token('space.100')}`,
	zIndex: layers.dialog(),
});

const viewModeStyles = css({
	//z index is set to 1 to ensure that the object sidebar is below the inline comment component
	zIndex: 1,
});

const quickstartSpacing = css({
	marginBottom: `${token('space.1000')}`,
	right: `${token('space.300')}`,
});

const quickstartLivePageSpacing = css({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginBottom: '110px',
	right: `${token('space.300')}`,
});

const objectSidebarContainerStyles = xcss({
	padding: 'space.100',
	boxShadow: 'elevation.shadow.raised',
	backgroundColor: 'elevation.surface.raised',
	borderRadius: '64px',
	width: '48px',
	marginLeft: 'auto',
});

const dividerStyle = xcss({
	borderBottom: `2px solid ${token('color.border')}`,
	display: 'inline-block',
	width: '16px',
	height: '1px',
});

const bottomSidebarStyles = xcss({
	marginTop: 'space.100',
});

const dotIconStyle = css({
	height: '6px',
	width: '6px',
	backgroundColor: token('color.icon.brand'),
	borderRadius: '50%',
	border: `2px solid ${token('color.border.inverse')}`,
	position: 'absolute',
	right: '3px',
	top: '3px',
});

const commentIconContainerStyle = css({
	position: 'relative',
});

const i18n = defineMessages({
	openCommentsPanelLabel: {
		id: 'object-sidebar-components.open.comments.panel.label',
		defaultMessage: 'Open Comments Panel',
		description: 'Label for button to open the comments panel',
	},
	openDetailsPanelLabel: {
		id: 'object-sidebar-components.open.details.panel.label',
		defaultMessage: 'Open Details Panel',
		description: 'Label for button to open the details panel',
	},
	recordLoomLabel: {
		id: 'object-sidebar-components.record.loom.label',
		defaultMessage: 'Record a loom',
		description: 'Label for button to record a loom',
	},
	aiLogoAltText: {
		id: 'object-sidebar-components.floating-context-menu-button.logo-alt-text',
		defaultMessage: 'Atlassian Intelligence',
		description: 'The alt text for the Atlassian Intelligence logo icon',
	},
	unreadCommentsLabel: {
		id: 'object-sidebar-components.unread.comments.icon.label',
		description: 'aria-label for the comment button to display unread inline comments',
		defaultMessage: 'You have unread comments',
	},
});

type ObjectSidebarControlProps = {
	hasCommentsButton?: boolean;
	hasDetailsButton?: boolean;
	hasLoomButton?: boolean;
	hasAiButton?: boolean;
	contentId: string;
	spaceKey?: string;
	contentType?: string | null;
	lastModifiedDate?: string | null;
	pageCommentsSectionInView?: boolean;
};

export const ObjectSidebarControl = ({
	hasCommentsButton,
	hasDetailsButton,
	hasLoomButton,
	hasAiButton,
	contentId,
	spaceKey,
	contentType,
	lastModifiedDate,
	pageCommentsSectionInView,
}: ObjectSidebarControlProps) => {
	const { formatMessage } = useIntl();
	const { showModal } = useDialogs();
	const [{ unreadCommentsListState }] = useUnreadInlineComments();
	const { viewProps } = useAnnotations();
	const { setViewProps } = useAnnotationsDispatch();
	const { hasContentChanged } = useCommentContentContext();
	const { resetContentChanged } = useCommentContentDispatchContext();
	const { showCommentsPanel } = useShowCommentsPanel();
	const { showDetailsPanel } = useShowDetailsPanel();
	const [{ sidebarControlState }] = useObjectSidebar();
	const eventEmitter = getRendererAnnotationEventEmitter();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const pageMode = useGetPageMode();

	const handleClickCommentsButton = useCallback(() => {
		const handleClick = () => {
			// Close the view component if it's open
			if (viewProps) {
				// Inform the Renderer to de-select the annotation
				eventEmitter.emit(AnnotationUpdateEvent.DESELECT_ANNOTATIONS);
				eventEmitter.emit(AnnotationUpdateEvent.REMOVE_ANNOTATION_FOCUS);
				// Remove the display of the comment
				setViewProps(null);
			}

			showCommentsPanel();
		};

		if (hasContentChanged) {
			showModal(CommentWarningDialog, {
				onConfirm: () => {
					resetContentChanged();
					handleClick();
				},
			});
		} else {
			handleClick();
		}

		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'commentsPanel',
				source: 'contentWrapper',
			},
		}).fire();
	}, [
		viewProps,
		hasContentChanged,
		eventEmitter,
		setViewProps,
		showCommentsPanel,
		resetContentChanged,
		showModal,
		createAnalyticsEvent,
	]);
	const isLivePage = useIsLivePage();

	const { isDismissed } = useQuickstartState();
	const { isQuickstartEligible } = useQuickstartEligible();
	const isQuickStartPillShowing = !isDismissed && isQuickstartEligible();

	const handleClickDetailsButton = useCallback(() => {
		showDetailsPanel();
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'detailsPanel',
				source: 'contentWrapper',
			},
		}).fire();
	}, [showDetailsPanel, createAnalyticsEvent]);

	const primaryActions: JSX.Element[] = [];
	if (hasCommentsButton && fg('confluence-frontend-comments-panel')) {
		primaryActions.push(
			<div css={commentIconContainerStyle}>
				<IconButton
					onClick={handleClickCommentsButton}
					appearance="subtle"
					shape="circle"
					icon={CommentIcon}
					label={formatMessage(i18n.openCommentsPanelLabel)}
					testId="sidebar-comments-button"
				/>
				{unreadCommentsListState.length > 0 && (
					<div css={dotIconStyle} aria-label={formatMessage(i18n.unreadCommentsLabel)} />
				)}
			</div>,
		);
	}
	if (hasDetailsButton && fg('confluence_frontend_details_panel')) {
		primaryActions.push(
			<IconButton
				onClick={handleClickDetailsButton}
				appearance="subtle"
				shape="circle"
				icon={AlignLeftIcon}
				label={formatMessage(i18n.openDetailsPanelLabel)}
				testId="sidebar-details-button"
			/>,
		);
	}

	const secondaryActions: JSX.Element[] = [];
	if (hasLoomButton && fg('confluence_frontend_object_sidebar_loom')) {
		secondaryActions.push(
			<PageHeaderLoomEntryPoint
				contentId={contentId}
				spaceKey={spaceKey ?? ''}
				contentType={contentType ?? ''}
				isCircle
				testId="sidebar-loom-button"
				source="contentWrapper"
			/>,
		);
	}

	return sidebarControlState === 'shown' ? (
		<div
			css={[
				objectSidebarStyles,
				pageMode === PageMode.VIEW && viewModeStyles,
				isQuickStartPillShowing && !isLivePage && quickstartSpacing,
				isQuickStartPillShowing && isLivePage && quickstartLivePageSpacing,
			]}
			data-testid="object-sidebar-container"
			data-vc="object-sidebar-container"
		>
			{primaryActions.length + secondaryActions.length > 0 &&
				fg('confluence_frontend_object_sidebar') && (
					<Flex
						xcss={objectSidebarContainerStyles}
						direction="column"
						gap="space.100"
						justifyContent="center"
						alignItems="center"
						testId="object-sidebar-control"
					>
						{primaryActions}
						{secondaryActions.length > 0 && <Box xcss={dividerStyle} />}
						{secondaryActions}
					</Flex>
				)}
			{hasAiButton && (
				<Box xcss={bottomSidebarStyles}>
					<AIFloatingContextMenu
						spaceKey={spaceKey ?? ''}
						contentId={contentId}
						contentType={contentType ?? ''}
						lastModifiedDate={lastModifiedDate}
						pageCommentsSectionInView={pageCommentsSectionInView}
						isInObjectSideBar
					/>
				</Box>
			)}
		</div>
	) : null;
};
