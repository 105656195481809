import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import ShieldIcon from '@atlaskit/icon/core/shield';
import Skeleton from '@atlaskit/skeleton';

import {
	ContentClassificationTag,
	useIsDataClassificationEnabledForOrg,
} from '@confluence/data-classification';
import { usePageContentId, useContentType } from '@confluence/page-context';

import { useContentClassification } from '../hooks/useContentClassification';

import { DetailsPanelItem } from './DetailsPanelItem';
import { NoneLabel } from './NoneLabel';
import { InlineRetryError } from './InlineRetryError';

const i18n = defineMessages({
	classificationSection: {
		id: 'details-panel.activity-section.classification-row',
		defaultMessage: 'Classification',
		description:
			"A title that appears next to the content's classification (highly confidential, confidential, internal, public)",
	},
});

export const ClassificationRow = () => {
	const { formatMessage } = useIntl();
	const [contentType] = useContentType();
	const [contentId] = usePageContentId();
	const { data, error, loading, refetch } = useContentClassification();

	const { isEnabled: isDataClassificationEnabled } = useIsDataClassificationEnabledForOrg();

	if (!isDataClassificationEnabled) return null;

	const renderClassificationItem = () => {
		if (loading || !contentType) {
			return (
				<Skeleton width="80px" height="24px" borderRadius={3} testId="classification-row-loading" />
			);
		}

		if (error) {
			return <InlineRetryError error={error} refetch={refetch} />;
		}

		if (data.hasNoClassification) {
			return <NoneLabel />;
		}

		return (
			<ContentClassificationTag
				contentId={contentId ?? ''}
				contentType={contentType}
				contentClassificationMetadata={data}
			/>
		);
	};

	return (
		<DetailsPanelItem
			itemName={formatMessage(i18n.classificationSection)}
			icon={<ShieldIcon label="" />}
		>
			{renderClassificationItem()}
		</DetailsPanelItem>
	);
};
