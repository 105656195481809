import { usePageContentId, usePageSpaceKey } from '@confluence/page-context';
import { useContentClassification as useContentClassificationMetadata } from '@confluence/data-classification/entry-points/queries';

import { useContentStatus } from './useContentStatus';
import { ContentStatus } from './contentStatus';

export const useContentClassification = () => {
	const [contentId] = usePageContentId();
	const [spaceKey] = usePageSpaceKey();

	const {
		contentStatus,
		loading: isContentStatusLoading,
		error: contentStatusError,
		refetch: refetchContentStatus,
	} = useContentStatus();

	const contentClassificationMetadata = useContentClassificationMetadata({
		contentId,
		spaceKeyOverride: spaceKey,
		contentStatusContext:
			contentStatus === ContentStatus.DRAFT ? 'draft-only' : 'current-and-draft',
		shouldSkipQueries: !contentStatus,
	});

	const isContentClassificationMetadataLoading =
		contentClassificationMetadata.contentLevelLoading ||
		contentClassificationMetadata.spaceLevelLoading;

	return {
		data: contentClassificationMetadata,
		loading: isContentStatusLoading || isContentClassificationMetadataLoading,
		error: contentStatusError,
		refetch: refetchContentStatus,
	};
};
