import gql from 'graphql-tag';

export const DetailsPanelContentReactionsQuery = gql`
	query DetailsPanelContentReactionsQuery($contentId: ID!, $status: String!) {
		content(id: $contentId, status: [$status]) {
			nodes {
				id
				contentReactionsSummary {
					reactionsCount
					ari
					containerAri
					reactionsSummaryForEmoji {
						emojiId
						count
						reacted
						id
					}
				}
			}
		}
	}
`;
