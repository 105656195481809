import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import { Stack, xcss } from '@atlaskit/primitives';

import { UpdatedRow } from './UpdatedRow';
import { ReactionsRow } from './ReactionsRow';
import { CommentsRow } from './CommentsRow';
import { ViewsRowWrapper as ViewsRow } from './ViewsRow';

const i18n = defineMessages({
	activitySectionTitle: {
		id: 'details-panel.activity-section.title',
		defaultMessage: 'Activity',
		description: 'Title for section containing activity related to content',
	},
});

const activitySectionStyles = xcss({
	paddingLeft: 'space.300',
	paddingRight: 'space.300',
	marginTop: 'space.300',
});

const itemsStyles = xcss({
	paddingLeft: 'space.075',
	paddingRight: 'space.075',
});

export const ActivitySection = () => (
	<Stack testId="activity-section" xcss={activitySectionStyles}>
		<FormattedMessage {...i18n.activitySectionTitle} tagName="h4" />
		<Stack xcss={itemsStyles}>
			<UpdatedRow />
			<ViewsRow />
			<CommentsRow />
			<ReactionsRow />
		</Stack>
	</Stack>
);
