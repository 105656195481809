import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import TaskIcon from '@atlaskit/icon/core/task';
import { Text, Inline } from '@atlaskit/primitives';

import { ContentStateColor } from '@confluence/content-state';

import { useContentState, type AvailableContentState } from '../hooks/useContentState';

import { DetailsPanelItem } from './DetailsPanelItem';
import { InlineRetryError } from './InlineRetryError';
import { NoneLabel } from './NoneLabel';

const i18n = defineMessages({
	status: {
		id: 'details-panel.properties-section.status-row',
		defaultMessage: 'Status',
		description:
			"Text that appears next to the content's status (rough draft, in-progress, ready for review)",
	},
});

const StatusRowContent = ({
	color,
	restrictionLevel,
	name,
	isContentStateAvailable,
}: AvailableContentState) =>
	isContentStateAvailable ? (
		<Inline alignBlock="center">
			<ContentStateColor
				color={color}
				appearance="rendererContentState"
				restrictionLevel={restrictionLevel}
			/>
			<Text>{name}</Text>
		</Inline>
	) : (
		<NoneLabel />
	);

export const StatusRow = (contentState: AvailableContentState) => {
	const { formatMessage } = useIntl();
	const { data, error, refetch } = useContentState(contentState);
	const { color, restrictionLevel, name } = data;
	const isContentStateAvailable = !!color || !!restrictionLevel || !!name;

	return (
		<DetailsPanelItem itemName={formatMessage(i18n.status)} icon={<TaskIcon label="" />}>
			{error ? (
				<InlineRetryError error={error} refetch={refetch} />
			) : (
				<StatusRowContent
					name={name}
					color={color}
					restrictionLevel={restrictionLevel}
					isContentStateAvailable={isContentStateAvailable}
				/>
			)}
		</DetailsPanelItem>
	);
};
