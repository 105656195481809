import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import EmojiIcon from '@atlaskit/icon/core/emoji';
import Skeleton from '@atlaskit/skeleton';

import { useSessionData } from '@confluence/session-data';
import { Reactions, ReactionLocation, type ReactionContainerType } from '@confluence/reactions';
import { useContentType, usePageContentId, usePageSpaceKey } from '@confluence/page-context';

import { useContentReactions } from '../hooks/useContentReactions';

import { InlineRetryError } from './InlineRetryError';
import { DetailsPanelItem } from './DetailsPanelItem';
import { NoneLabel } from './NoneLabel';

const i18n = defineMessages({
	reactionsSection: {
		id: 'details-panel.activity-section.reactions-row',
		defaultMessage: 'Reactions',
		description: 'Text that appears next to the number of reactions to content and the emojis used',
	},
});

export const ReactionsRow = () => {
	const { formatMessage } = useIntl();
	const [contentType] = useContentType();
	const [spaceKey] = usePageSpaceKey();
	const [contentId] = usePageContentId();

	const { data, error, refetch, loading, updateCache } = useContentReactions();
	const { userId } = useSessionData();
	const isReadOnly = !Boolean(userId);

	const renderReactionsItem = () => {
		if (loading) {
			return (
				<Skeleton width="48px" height="24px" borderRadius={3} testId="reactions-row-loading" />
			);
		}

		if (error) {
			return <InlineRetryError error={error} refetch={refetch} />;
		}

		if (isReadOnly && data?.reactionsCount === 0) {
			return <NoneLabel />;
		}

		return (
			<Reactions
				updateCache={updateCache}
				contentId={contentId ?? ''}
				containerId={spaceKey ?? ''}
				reactionsData={data}
				// The location determines the Reactions' styling.
				// If necessary, we can add a distinct "Details Panel Reaction Location."
				location={ReactionLocation.FEED_PAGE}
				containerType={contentType as ReactionContainerType}
				summaryViewEnabled
				isSubtle
				readOnly={isReadOnly}
			/>
		);
	};

	return (
		<DetailsPanelItem itemName={formatMessage(i18n.reactionsSection)} icon={<EmojiIcon label="" />}>
			{renderReactionsItem()}
		</DetailsPanelItem>
	);
};
