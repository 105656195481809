import { baseNormalizeData } from './baseNormalizeData';

export const normalizeContentReactionsData = (data: any) => {
	const { contentReactionsSummary } = baseNormalizeData(data);

	return {
		ari: contentReactionsSummary.ari,
		containerAri: contentReactionsSummary.containerAri,
		reactionsCount: contentReactionsSummary.reactionsCount,
		reactionsSummaryForEmoji: contentReactionsSummary.reactionsSummaryForEmoji ?? [],
	};
};
