import { useMemo } from 'react';
import { useQuery } from 'react-apollo';

import { usePageContentId, useContentType } from '@confluence/page-context';

import { DetailsPanelRelatedContentQuery } from '../queries/DetailsPanelRelatedContentQuery.graphql';
import type {
	DetailsPanelRelatedContentQueryVariables as RelatedContentQueryVariables,
	DetailsPanelRelatedContentQuery as RelatedContentQuery,
} from '../queries/__types__/DetailsPanelRelatedContentQuery';
import { normalizeRelatedContentData } from '../normalizers/normalizeRelatedContentData';

export const GET_RECOMMENDED_PAGES_EXPERIENCE = 'end_of_page';

export const useRelatedContent = () => {
	const [contentId] = usePageContentId();
	const [contentType] = useContentType();

	const { data, loading, error, refetch } = useQuery<
		RelatedContentQuery,
		RelatedContentQueryVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		DetailsPanelRelatedContentQuery,
		{
			variables: {
				contentId: contentId ?? '',
				contentType: contentType ?? '',
				experience: GET_RECOMMENDED_PAGES_EXPERIENCE,
			},
			skip: !contentId || !contentType,
		},
	);

	const normalizedData = useMemo(() => normalizeRelatedContentData(data), [data]);

	return {
		data: normalizedData,
		loading,
		error,
		refetch,
	};
};
