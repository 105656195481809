import React from 'react';

import { Inline, Text, xcss } from '@atlaskit/primitives';
import { LinkButton, type LinkButtonProps } from '@atlaskit/button/new';

const itemStyles = xcss({
	paddingTop: 'space.100',
});

type DetailsPanelItemProps = {
	icon: React.JSX.Element;
	itemName: string;
};

type DetailsPanelItemLinkButtonProps = {
	onClick?: () => void;
	href?: string;
	iconAfter?: LinkButtonProps['iconAfter'];
	isDisabled?: boolean;
};

export const DetailsPanelItemLinkButton: React.FC<DetailsPanelItemLinkButtonProps> = ({
	onClick = () => {},
	href = '#',
	iconAfter,
	children,
	isDisabled,
}) => {
	return (
		<LinkButton
			href={href}
			onClick={onClick}
			isDisabled={isDisabled}
			appearance="subtle"
			spacing="compact"
			iconAfter={iconAfter}
		>
			<Text color="color.link">{children}</Text>
		</LinkButton>
	);
};

export const DetailsPanelItem: React.FC<DetailsPanelItemProps> = ({ icon, itemName, children }) => {
	return (
		<Inline spread="space-between" alignBlock="center" xcss={itemStyles}>
			<Inline space="space.100" alignBlock="center">
				{icon}
				<Text>{itemName}</Text>
			</Inline>
			{children}
		</Inline>
	);
};
