import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import AlignLeftIcon from '@atlaskit/icon/core/align-left';

const i18n = defineMessages({
	detailsPanelHeaderIcon: {
		id: 'details-panel.panel.header.icon.label',
		defaultMessage: 'Details Panel Icon',
		description: 'Label for the details panel header icon',
	},
});

export const DetailsPanelHeaderIcon = () => {
	const { formatMessage } = useIntl();

	return <AlignLeftIcon label={formatMessage(i18n.detailsPanelHeaderIcon)} />;
};
