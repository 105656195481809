import { useQuery } from 'react-apollo';

import { usePageContentId } from '@confluence/page-context';

import { DetailsPanelContentStatusQuery } from '../queries/DetailsPanelContentStatusQuery.graphql';
import type {
	DetailsPanelContentStatusQuery as ContentStatusQuery,
	DetailsPanelContentStatusQueryVariables as ContentStatusQueryVariables,
} from '../queries/__types__/DetailsPanelContentStatusQuery';

type UseContentStatusProps = {
	skip?: boolean;
};

export const useContentStatus = ({ skip = false }: UseContentStatusProps = {}) => {
	const [contentId] = usePageContentId();
	const { loading, error, data, refetch } = useQuery<
		ContentStatusQuery,
		ContentStatusQueryVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		DetailsPanelContentStatusQuery,
		{
			skip: skip || !contentId,
			variables: {
				contentId: contentId ?? '',
			},
		},
	);

	return {
		contentStatus: data?.content?.nodes?.[0]?.status,
		loading,
		error,
		refetch,
	};
};
