import { useMemo } from 'react';
import { useQuery } from 'react-apollo';

import { usePageContentId } from '@confluence/page-context';

import { DetailsPanelContentReactionsQuery } from '../queries/DetailsPanelContentReactionsQuery.graphql';
import type {
	DetailsPanelContentReactionsQueryVariables as ContentReactionsQueryVariables,
	DetailsPanelContentReactionsQuery as ContentReactionsQuery,
} from '../queries/__types__/DetailsPanelContentReactionsQuery';
import { normalizeContentReactionsData } from '../normalizers/normalizeContentReactionsData';

import { useContentStatus } from './useContentStatus';
import { ContentStatus } from './contentStatus';
import { updateContentReactionsCache } from './updateContentReactionsCache';

export const useContentReactions = () => {
	const [contentId] = usePageContentId();

	const {
		contentStatus,
		loading: isContentStatusLoading,
		error: contentStatusError,
		refetch: refetchContentStatus,
	} = useContentStatus();

	const { data, loading, error, refetch, updateQuery } = useQuery<
		ContentReactionsQuery,
		ContentReactionsQueryVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		DetailsPanelContentReactionsQuery,
		{
			variables: {
				contentId: contentId ?? '',
				status: contentStatus ?? '',
			},
			skip: isContentStatusLoading || !!contentStatusError || !contentStatus || !contentId,
		},
	);

	const normalizedData = useMemo(() => normalizeContentReactionsData(data), [data]);

	return {
		data: normalizedData,
		loading,
		error: contentStatusError || error,
		refetch: !!contentStatusError ? refetchContentStatus : refetch,
		updateCache:
			contentStatus === ContentStatus.DRAFT ? updateContentReactionsCache(updateQuery) : undefined,
	};
};
