import { baseNormalizeData } from './baseNormalizeData';

export const normalizeContentOwnerData = (data: any) => {
	const { ownedBy } = baseNormalizeData(data);

	return {
		displayName: ownedBy.displayName,
		accountId: ownedBy.accountId,
		profilePicturePath: ownedBy.profilePicture?.path,
	};
};
