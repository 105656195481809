import gql from 'graphql-tag';

export const DetailsPanelContentBodyQuery = gql`
	query DetailsPanelContentBodyQuery($contentId: ID, $status: String!) {
		content(id: $contentId, status: [$status]) {
			nodes {
				id
				body {
					dynamic {
						value
						representation
					}
				}
			}
		}
	}
`;
