import { baseNormalizeData } from './baseNormalizeData';

export const normalizeContentCreatorData = (data: any) => {
	const { createdBy } = baseNormalizeData(data);

	return {
		accountId: createdBy.accountId,
		displayName: createdBy.displayName,
		profilePicturePath: createdBy.profilePicture?.path,
	};
};
