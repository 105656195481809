import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import Avatar from '@atlaskit/avatar';
import { Box, Inline, xcss, Text } from '@atlaskit/primitives';
import Link from '@atlaskit/link';
import ChangesIcon from '@atlaskit/icon/core/changes';
import Button from '@atlaskit/button/new';
import Skeleton from '@atlaskit/skeleton';

import {
	ContentOwnershipContextProvider,
	ContentOwnershipContextConsumer,
} from '@confluence/content-ownership';
import { ProfileCardWrapper } from '@confluence/profile';
import { PEOPLE_DIRECTORY } from '@confluence/named-routes';

import { useContentOwner } from '../hooks/useContentOwner';

import { InlineRetryError } from './InlineRetryError';

const panelHeaderStyles = xcss({
	paddingTop: 'space.100',
	paddingLeft: 'space.300',
	paddingRight: 'space.300',
});

const i18n = defineMessages({
	owner: {
		id: 'details-panel.owner-section.owner',
		defaultMessage: 'Owner',
		description: 'A label distinguishing the user as the owner of a page',
	},
	changePageOwner: {
		id: 'details-panel.owner-section.change-page-owner',
		defaultMessage: 'Change page owner',
		description: 'An icon label within a button used to change the owner of a page',
	},
	accessibleAvatarLabel: {
		id: 'details-panel.owner-section.accessible-avatar-label',
		defaultMessage: '{displayName}’s profile picture',
		description: "Alt text and aria-label for a user's profile picture",
	},
});

type ContentOwnershipContext = {
	isModalOpen: boolean;
	openModal: () => void;
	closeModal: () => void;
};

export const OwnerSection = () => {
	const { formatMessage } = useIntl();
	const { data, error, refetch, loading, isChangePageOwnerButtonVisible } = useContentOwner();
	const { accountId, profilePicturePath, displayName } = data;
	const profileURL = accountId ? `${PEOPLE_DIRECTORY.toUrl()}/${accountId}` : '';

	const renderOwnerSectionContent = () => {
		if (loading) {
			return (
				<Inline alignBlock="center" space="space.150" testId="owner-section-loading">
					<Skeleton width="40px" height="40px" borderRadius={200} />
					<Skeleton width="154px" height="32px" borderRadius={4} />
				</Inline>
			);
		}

		if (error) {
			return <InlineRetryError error={error} refetch={refetch} />;
		}

		return (
			<Inline spread="space-between" alignBlock="center">
				<Inline alignBlock="center" space="space.100">
					<ProfileCardWrapper userId={accountId}>
						<Avatar
							size="large"
							href={profileURL}
							src={profilePicturePath ?? undefined}
							name={formatMessage(i18n.accessibleAvatarLabel, {
								displayName,
							})}
						/>
					</ProfileCardWrapper>
					<Box>
						<Box>
							{/* To be replaced by Link introduced in https://stash.atlassian.com/projects/ATLASSIAN/repos/atlassian-frontend-monorepo/pull-requests/163767/overview */}
							<Link href={profileURL} appearance="subtle">
								<Text>{displayName}</Text>
							</Link>
						</Box>
						<Text size="small" color="color.text.subtle">
							{formatMessage(i18n.owner)}
						</Text>
					</Box>
				</Inline>
				{isChangePageOwnerButtonVisible && (
					<ContentOwnershipContextProvider>
						<ContentOwnershipContextConsumer>
							{({ openModal }: ContentOwnershipContext) => (
								<Button appearance="subtle" onClick={openModal}>
									<ChangesIcon label={formatMessage(i18n.changePageOwner)} />
								</Button>
							)}
						</ContentOwnershipContextConsumer>
					</ContentOwnershipContextProvider>
				)}
			</Inline>
		);
	};

	return (
		<Box xcss={panelHeaderStyles} key="owner-section">
			{renderOwnerSectionContent()}
		</Box>
	);
};
