// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Container } from 'unstated';

import type { ContentStateState, ContentState } from './types';

export const defaultValues: ContentStateState = {
	contentState: null,
	shouldRunContentStateEffect: true,
	defaultStatesArray: [],
	customStatesArray: [],
	isContentStateEnabled: true,
	isSuggestedEnabled: true,
	isCustomEnabled: true,
	isUpdating: false,
};
export class ContentStateStateContainer extends Container<ContentStateState> {
	state = { ...defaultValues };

	setContentState = (contentState: ContentState | null) => this.setState({ contentState });
	setShouldRunContentStateEffect = (shouldRunContentStateEffect: boolean) =>
		this.setState({ shouldRunContentStateEffect });
	setDefaultStatesArray = (defaultStatesArray: (ContentState | null)[]) =>
		this.setState({ defaultStatesArray });
	setCustomStatesArray = (customStatesArray: (ContentState | null)[]) =>
		this.setState({ customStatesArray });
	setIsContentStateEnabled = (isContentStateEnabled: boolean) =>
		this.setState({ isContentStateEnabled });
	setIsSuggestedEnabled = (isSuggestedEnabled: boolean) => this.setState({ isSuggestedEnabled });
	setIsCustomEnabled = (isCustomEnabled: boolean) => this.setState({ isCustomEnabled });
	setIsUpdating = (isUpdating: boolean) => this.setState({ isUpdating });
	setInitialValues = (contentStateState: ContentStateState) => this.setState(contentStateState);
	resetToDefaults = () => this.setState({ ...defaultValues });
}
